import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./style/index.scss";
import "./style/element-variables.scss";
import { Request } from "@/plugins/http";
import VueAxios from "vue-axios";
import ElementPlus from "element-plus";
import zhCn from "element-plus/es/locale/lang/zh-cn";
import Vant from "vant";
import "vant/lib/index.css";
import VConsole from "vconsole";
// const vConsole = new VConsole();

const app = createApp(App);
app.use(VueAxios, Request.init());
app.use(ElementPlus, {
  locale: zhCn,
});

createApp(App).use(store).use(router).use(Vant).mount("#app");
