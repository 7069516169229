import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { getToken, removeToken } from "@/plugins/cookie";
import { ElMessage } from "element-plus";
import { Toast } from "vant";
const is_mobile = navigator.userAgent.match(
  // eslint-disable-next-line no-irregular-whitespace
  /(phone|pod|iPhone|iPod|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|Symbian|Windows Phone)/i
);

// 10005  权限不够充值
export class Request {
  public static axiosInstance: AxiosInstance;

  public static init() {
    // 创建axios实例
    this.axiosInstance = axios.create({
      baseURL: "",
      // timeout: 6000
    });
    // 初始化拦截器
    this.initInterceptors();
    return axios;
  }

  // 初始化拦截器
  public static initInterceptors() {
    // 设置post请求头
    // this.axiosInstance.defaults.headers.post['Content-Type'] = 'multipart/form-data';
    /**
     * 请求拦截器
     * 每次请求前，如果存在token则在请求头中携带token
     */
    this.axiosInstance.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        // 登录流程控制中，根据本地是否存在token判断用户的登录情况
        // 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token
        // 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码
        if (getToken()) {
          config.headers["Token"] = getToken();
        }
        return config;
      },
      (error: any) => {
        console.log(error);
      }
    );

    // 响应拦截器
    this.axiosInstance.interceptors.response.use(
      // 请求成功
      (response: AxiosResponse) => {
        const status = response.status;
        const res = response.data;
        let msg = "";
        switch (true) {
          case status < 200 || status >= 300:
            Request.errorHandle(response);
            break;
          case res.code === 200:
            break;
          case res.code === 403:
            is_mobile
              ? Toast(res.msg)
              : ElMessage.error({
                  duration: 600,
                  offset: 200,
                  message: res.msg,
                });
            break;
          case res.code === 10001:
            removeToken();
            ElMessage.error({
              duration: 600,
              offset: 200,
              message: res.msg,
            });
            // location.reload();
            break;
          case res.code === 10002:
            removeToken();
            // location.reload();
            break;
          case res.code === 10003:
            is_mobile
              ? Toast("签名不正确(10003)")
              : ElMessage.error({
                  duration: 600,
                  offset: 200,
                  message: "签名不正确(10003)",
                });
            break;
          case res.code === 10004:
            is_mobile
              ? Toast("用户不存在(10004)")
              : ElMessage.error({
                  duration: 600,
                  offset: 200,
                  message: "用户不存在(10004)",
                });
            break;
          default:
            return Promise.reject(response);
            break;
        }
        return response;
      },
      // 请求失败
      (error: any) => {
        const { response } = error;
        if (response) {
          // 请求已发出，但是不在2xx的范围
          Request.errorHandle(response);
          return Promise.reject(response.data);
        } else {
          // 处理断网的情况
          // eg:请求超时或断网时，更新state的network状态
          // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
          // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
          is_mobile
            ? Toast("用网络连接异常,请稍后再试!")
            : ElMessage({
                message: "网络连接异常,请稍后再试!",
                type: "error",
              });
        }
      }
    );
  }

  /**
   * http握手错误
   * @param res 响应回调,根据不同响应进行不同操作
   */
  private static errorHandle(res: any) {
    // 状态码判断
    switch (res.status) {
      case 401:
        break;
      case 403:
        break;
      case 404:
        is_mobile
          ? Toast("请求的资源不存在")
          : ElMessage({
              message: "请求的资源不存在",
              type: "warning",
            });
        break;
      default:
        is_mobile
          ? Toast("连接错误")
          : ElMessage.error({
              duration: 600,
              offset: 200,
              message: "连接错误",
            });
    }
  }
}
