export function getToken(): string | boolean {
  return localStorage.getItem("HBB_ACCESS_TOKEN") ?? false;
}

export function setToken(token: string): void {
  localStorage.setItem("HBB_ACCESS_TOKEN", token);
}

export function removeToken(): void {
  localStorage.removeItem("HBB_ACCESS_TOKEN");
  localStorage.removeItem("HBB_ACCESS_INFO");
  localStorage.removeItem("HBB_ACCESS_AUTH");
  localStorage.removeItem("HBB_ACCESS_BACK_LIST");

  sessionStorage.clear();
}

export function setPassword(info: string): void {
  localStorage.setItem("HBB_ACCESS_PASSWORD", info);
}

export function getPassword(): any | boolean {
  return localStorage.getItem("HBB_ACCESS_PASSWORD") ?? false;
}

export function setUserInfo(info: string): void {
  localStorage.setItem("HBB_ACCESS_INFO", info);
}

export function getUserInfo(): any | boolean {
  return localStorage.getItem("HBB_ACCESS_INFO") ?? false;
}

export function setAuth(info: string): void {
  localStorage.setItem("HBB_ACCESS_AUTH", info);
}

export function getAuth(): any | boolean {
  return localStorage.getItem("ACCESS_AUTH") ?? false;
}
