import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
  RouteRecordRaw,
} from "vue-router";
import { getToken } from "@/plugins/cookie";

const is_mobile = navigator.userAgent.match(
  // eslint-disable-next-line no-irregular-whitespace
  /(phone|pod|iPhone|iPod|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|Symbian|Windows Phone)/i
);

// 外部菜单
export const externalMenu: Array<RouteRecordRaw> = [
  {
    path: "/external-master",
    name: "ExternalMaster",
    meta: {
      title: "达人库",
      icon: "",
      menuType: "External",
    },
    redirect: "/external-master/talent-pool",
    children: [
      {
        path: "/external-master/talent-pool",
        name: "TalentPool",
        component: () => import("../views/external/master/TalentPool.vue"),
        meta: {
          title: "达人库",
          icon: "",
          routeParent: "ExternalMaster",
        },
      },
      {
        path: "/external-master/author-detail",
        name: "AuthorDetail",
        component: () => import("../views/external/master/AuthorDetail.vue"),
        meta: {
          title: "达人详情",
          icon: "",
          routeParent: "ExternalMaster",
        },
      },
      {
        path: "/external-master/collect-list",
        name: "CollectList",
        component: () => import("../views/external/master/CollectList.vue"),
        meta: {
          title: "收藏列表",
          icon: "",
          routeParent: "ExternalMaster",
        },
      },
    ],
  },
  {
    path: "/external-goods",
    name: "ExternalGoods",
    meta: {
      title: "商品库",
      icon: "",
      menuType: "External",
    },
    redirect: "/external-goods/goods-list",
    children: [
      {
        path: "/external-goods/goods-list",
        name: "GoodsList",
        component: () => import("../views/external/goods/GoodsList.vue"),
        meta: {
          title: "商品库",
          icon: "",
          routeParent: "ExternalGoods",
        },
      },
      {
        path: "/external-goods/goods-detail",
        name: "GoodsDetail",
        component: () => import("../views/external/goods/GoodsDetail.vue"),
        meta: {
          title: "商品详情",
          icon: "",
          routeParent: "ExternalGoods",
        },
      },
    ],
  },
  {
    path: "/external-merchant",
    name: "ExternalMerchant",
    meta: {
      title: "商单",
      icon: "",
      menuType: "External",
    },
    redirect: "/external-merchant/list",
    children: [
      {
        path: "/external-merchant/list",
        name: "MerchantList",
        component: () => import("../views/external/merchant/List.vue"),
        meta: {
          title: "商单",
          icon: "",
          routeParent: "ExternalMerchant",
        },
      },
    ],
  },
  {
    path: "/idustry-trends",
    name: "IndustryTrends",
    meta: {
      title: "行业动态",
      icon: "",
      menuType: "External",
    },
    redirect: "/idustry-trends/list",
    children: [
      {
        path: "/idustry-trends/list",
        name: "TrendsList",
        component: () => import("../views/external/IndustryTrends.vue"),
        meta: {
          title: "行业动态",
          icon: "",
          routeParent: "IndustryTrends",
        },
      },
      {
        path: "/idustry-trends/views",
        name: "TrendsView",
        component: () => import("../views/external/TrendsView.vue"),
        meta: {
          title: "动态详情",
          icon: "",
          routeParent: "IndustryTrends",
        },
      },
    ],
  },
  {
    path: "/upgrade",
    name: "Upgrade",
    meta: {
      title: "会员升级",
      icon: "",
      menuType: "External",
    },
    redirect: "/upgrade/info",
    children: [
      {
        path: "/upgrade/info",
        name: "UpgradeInfo",
        component: () => import("../views/external/Upgrade.vue"),
        meta: {
          title: "会员升级",
          icon: "",
          routeParent: "Upgrade",
        },
      },
    ],
  },
];

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "External",
    component: () => import("../views/external/HomeView.vue"),
    meta: {
      title: "首页",
      icon: "",
    },
    redirect: "/external/home",
    children: [
      {
        path: "/external/home",
        name: "externalHome",
        component: () => import("../views/external/home/HomeInfo.vue"),
        meta: {
          title: "首页",
          icon: "",
          menuType: "External",
        },
      },
      ...externalMenu,
    ],
  },
  {
    path: "/external-login",
    name: "ExternalLogin",
    component: () => import("../views/external/Login.vue"),
    meta: {
      title: "登录",
      icon: "",
    },
  },
  {
    path: "/404",
    name: "404",
    component: () => import("../views/404.vue"),
    meta: {
      title: "404",
      icon: "",
    },
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

const BaseTitle = "听海云";

// router.beforeEach(async (to, from, next) => {
//   document.title = (to.meta.title ? to.meta.title : BaseTitle) as string;
//   // next();

//   // return;
//   const BaseWhiteList = ["ExternalLogin"];
//   switch (true) {
//     case !getToken() && !BaseWhiteList.includes(to.name as string):
//       next({ name: "ExternalLogin" });
//       break;
//     case getToken() && BaseWhiteList.includes(to.name as string):
//       next({ name: "External" });
//       break;
//     default:
//       next();
//       break;
//   }
// });
export default router;
